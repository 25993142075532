// eslint-disable-next-line max-len
const phoneNumberRegex = /^1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[235-8]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|66\d{2})\d{6}$/

/**
 * @description regex from https://github.com/VincentSit/ChinaMobilePhoneNumberRegex/blob/master/README-CN.md
 * modified according to backend
 */
export function validatePhoneNumber (phoneNumber?: number | string): boolean {
  return phoneNumber ? phoneNumberRegex.test(phoneNumber.toString()) : false
}
