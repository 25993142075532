import React from 'react'
import { Link } from 'react-router-dom'
import withPageHeader from 'src/hoc/withPageHeader'
import styles from './notFound.module.scss'

const NotFound: React.FC = () => {

  return (
    <div className={styles.page}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.message}>
            <p>未找到相关页面</p>
            <Link to="/">回到首页</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withPageHeader(NotFound)
