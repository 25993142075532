import { useState, useEffect } from 'react'
import uniqueId from 'lodash/uniqueId'
import { flatMap, map } from 'lodash'
import DataTableAPI from 'src/api/datatables'
import { Bound, DataTable, SearchReturn } from 'src/types/datatable'
import { SearchState } from 'src/hooks/common/useRouter'

export function useDataTable (): DataTable[] {
  const [dataTable, setDataTable] = useState<DataTable[]>()

  useEffect(() => {
    async function getData () {
      const data = await DataTableAPI.get()
      setDataTable(data)
    }
    getData()
  }, [])

  return dataTable ?? []
}
interface Return {
  searchResults: SearchReturn[]
  isSearching: boolean;
}

export function useSearchDataTable (state: SearchState) : Return {
  const [searchResults, setSearchResults] = useState<SearchReturn[]>([])
  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {

    async function initSearch () {
      setSearchResults([])
      setIsSearching(true)
      const promises = flatMap(state.dataTable, d => {
        if (state.bounds) {
          return [getData(d, undefined, state.bounds)]
        }
        else {
          return map(state.region, r => getData(d, r))
        }
      })
      try {
        await Promise.all(promises)
      }
      finally {
        setIsSearching(false)
      }
    }

    async function getData (dataTable: DataTable, divisionCode?: string, bound?: Bound) {
      const data = await DataTableAPI.search({
        dataTable: dataTable.name,
        dataTableAlias: dataTable.alias,
        divisionCode,
        bound
      })
      if (data) {
        setSearchResults(prev => {
          return prev.concat({
            ...data,
            id: uniqueId()
          })
        })
      }
    }

    initSearch()
  }, [state])

  return {
    searchResults,
    isSearching
  }
}