import React from 'react'
import { Modal, Table } from 'antd'
import clsx from 'clsx'
import { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import { SearchResultItem } from 'src/hooks/database/useDatabase'
import styles from './databasePreviewModal.module.scss'


interface Props {
  dialog: Dialog;
  database?: SearchResultItem
}

export const DatabasePreviewModal: React.FC<Props> = ({ dialog, database }) => {
  const previewColumns = database?.columns.map(c => {
    return {
      title: c.description,
      dataIndex: c.name
    }
  })
  
  const previewData = database?.preview
  
  const fieldColumns = [
    {
      title: '字段名称',
      dataIndex: 'name'
    },
    {
      title: '描述',
      dataIndex: 'description'
    },
    {
      title: '类型',
      dataIndex: 'type'
    }
  ]
    
  const fieldData = database?.columns.map(c => {
    return {
      id: c.name,
      name: c.name,
      description: c.description,
      type: c.type
    }
  })
  

  return (
    <Modal
      centered
      onCancel={() => dialog.close()}
      className={styles.modal}
      footer={null}  
      visible={dialog.visible} 
      width={1200} >
      <div className={styles.title}>
        {database?.name ?? ''}
      </div>
      <div className={styles.sectionTitle}>
      数据预览
      </div>
      <Table
        className={clsx(styles.table, styles.previewTable)} rowKey="id"
        dataSource={previewData} columns={previewColumns} size="small" 
        scroll={{ x: 'max-content' }} pagination={{ hideOnSinglePage: true }}
        rowClassName={(_, i) => i%2 ? styles.oddRow : styles.evenRow} />
      <div className={styles.sectionTitle}>
      字段列表
        <div className={styles.sectionInfo}>{fieldData?.length ?? 0} 个变量</div>
      </div>
      <Table
        className={clsx(styles.table, styles.fieldTable)} rowKey="id"
        dataSource={fieldData} columns={fieldColumns} size="small" 
        scroll={{ x: 'max-content', y: '40vh' }} pagination={false}
      />
    </Modal>
  )
}

export default DatabasePreviewModal