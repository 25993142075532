
import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import size from 'lodash/size'
import { Dropdown, Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { DownOutlined } from '@ant-design/icons'
import filter from 'lodash/filter'
import { DataTable } from 'src/types/datatable'
import styles from './databaseSelect.module.scss'

type CheckboxValue = string | number | boolean;

interface Props {
  dataTable: DataTable[];
  value?: DataTable[];
  onSelect?: (d: Array<DataTable>) => void;
}

export const DatabaseSelect: React.FC<Props> = ({
  dataTable,
  value,
  onSelect
}) => {
  const options = map(dataTable, d => {
    return {
      value: d.name,
      label: d.alias
    }
  })
  const [indeterminate, setIndeterminate] = useState(false)
  const [selected, setSelected] = useState<Array<CheckboxValue>>(map(value, v => v.name))
  const [isAllChecked, setIsAllChecked] = useState(true)
  
  useEffect(() => {
    value && setSelected(map(value, v => v.name))
  }, [value])
  
  useEffect(() => {
    setIndeterminate(size(selected) < size(options) && size(selected) > 0)
    setIsAllChecked(size(selected) === size(options))
  }, [options, selected])

  function handleCheckAllChange (e: CheckboxChangeEvent) {
    const checkedValue = e.target.checked ? map(options, o => o.value) : []
    setSelected(checkedValue) 
    handleSelect(checkedValue)
  }

  function handleChange (checkedValue: typeof selected) {
    setSelected(checkedValue) 
    handleSelect(checkedValue)
  }

  function handleSelect (checkedValue: typeof selected) {
    onSelect?.(filter(dataTable, d => checkedValue.includes(d.name)))
  }

  const menu = ( 
    <div className={styles.select}>
      <div className={styles.item} onClick={(e) => e.stopPropagation()}>
        <Checkbox indeterminate={indeterminate} onChange={handleCheckAllChange} checked={isAllChecked}>
          全选
        </Checkbox>
      </div>
      <Checkbox.Group className={styles.group} value={selected} onChange={handleChange}>
        {
          map(options, o => {
            return (
              <div className={styles.item} key={o.label} onClick={(e) => e.stopPropagation()}>
                <Checkbox value={o.value}>{o.label}</Checkbox> 
              </div>
            )
          })
        }
      </Checkbox.Group>
    </div>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.selectedWrap}>
        <div className={styles.selected}>
          <span>
            {
              isAllChecked ? '所有数据库' : `${size(selected)} 个数据库`
            }
          </span>
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  )
}

export default DatabaseSelect