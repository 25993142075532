import { Link } from 'react-router-dom'
import { Divider, Dropdown, Menu, Modal } from 'antd'
import { ReactComponent as Logo } from 'src/assets/icons/logo.svg'
import { ReactComponent as PersonIcon } from 'src/assets/icons/person.svg'

import { useAuth } from 'src/hooks/common/useAuth'
import styles from './pageHeader.module.scss'

export const PageHeader: React.FC = () => {
  const { logout } = useAuth()
  function handleLogoutClick () {
    Modal.confirm({
      content: '您确定要退出登录吗？',
      onOk: logout,
      okText: '确定退出',
      okType: 'danger',
      cancelText: '取消'
    })
  }

  return (
    <div className={styles.header}>
      <div>
        <Link to="/" className={styles.logoLink}>
          <Logo />
          <Divider type="vertical" className={styles.divider} />
          <span className={styles.title}>量城内部数据获取工具</span>
        </Link>
      </div>
      <div></div>
      <Dropdown
        overlay={
          <Menu> 
            <Menu.Item danger onClick={handleLogoutClick} key="logout">
                退出登录
            </Menu.Item>
          </Menu>}> 
        <div className={styles.user}>
          <PersonIcon />
        </div>
      </Dropdown>
    </div>
  )
}

export default PageHeader