import { useState } from 'react'
import map from 'lodash/map'
import { Modal, Upload, message } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import { ArrowRightOutlined, CloudUploadOutlined } from '@ant-design/icons'
import iconImport from 'src/assets/icons/import.png'
import geojson from 'src/assets/icons/geojson.svg'
import json from 'src/assets/icons/json.svg'
import { Geometry } from 'src/utils/wkt'
import styles from './import.module.scss'

interface Props {
  className?: string;
  onUpload: (coordinates: Geometry['coordinates']) => void;
}

const { Dragger } = Upload

export const Import: React.FC<Props> = ({
  className,
  onUpload
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  function handleClick () {
    setModalVisible(true)
  }

  async function handleUpload (options: UploadRequestOption) {
    const file = options.file as File
    try {
      const text = await file.text()
      const geoJson = JSON.parse(text)
      const coordinates = map(geoJson.features, f => {
        return f.geometry.coordinates
      })
      onUpload(coordinates)
      setModalVisible(false)
    } catch (e) {
      message.error('上传失败，请检查上传文件格式是否正确')
    }
  }

  return (
    <>
      <div className={className} onClick={handleClick}>
        <div>
          <img src={iconImport} alt="icon" className={styles.icon} />
          <span>导入边界获取数据</span>
        </div>
        <ArrowRightOutlined />
      </div> 
      <Modal
        centered
        className={styles.modal}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        title="导入边界"
        footer={null}
      >
        <Dragger
          name="file"
          customRequest={handleUpload}
          accept=".json,.geojson"
          multiple={false}
          showUploadList={false}
        >
          <div className={styles.upload}>
            <div className={styles.uploadIcons}>
              <img src={json} alt="json" />
              <img src={geojson} alt="geojson" />
            </div>
            <div>
              <CloudUploadOutlined /> 点击上传或拖拽文件在此处
            </div>
            <div className={styles.uploadTips}>
              支持JSON、GeoJSON格式
            </div>
          </div>
        </Dragger>
      </Modal>
    </>
  )
}

export default Import