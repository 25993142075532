import { useState } from 'react'
import { Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import map from 'lodash/map'
import { queryCodes, queryRegion } from 'src/utils/region'
import styles from './searchSelect.module.scss'

const { Option } = Select

type OptionType = {
  value: string;
  name: string;
  region: string;
}
interface Props {
  defaultValue?: string[];
  customBoundName?: string;
  onSearch?: (val: string[]) => void
}
function getDefaultOptions (v: string[]) {
  return queryCodes(v).map(r => {
    return {
      value: r.code,
      region: r.text,
      name: r.name
    }
  })
}
export const SearchSelect: React.FC<Props> = ({ defaultValue, onSearch, customBoundName }) => {
  const [options, setOptions] = useState<OptionType[]>(defaultValue ? getDefaultOptions(defaultValue) : [])
  const [value, setValue] = useState<string[]>(defaultValue ?? (customBoundName ? [customBoundName] : []))

  function handleSearch (val: string) {
    if (customBoundName && value[0] === customBoundName) {
      return
    }
    if (val) {
      const result = queryRegion(val)
      setOptions(result.map(r => {
        return {
          value: r.code,
          region: r.text,
          name: r.name
        }
      }))
    }
    else {
      setOptions([])
    }
  }

  function handleChange (val: string[]){
    setValue(val)
  }

  return (
    <div className={styles.wrap}>
      <Select
        value={value}
        showSearch
        onSearch={handleSearch}
        className={styles.select}
        mode="multiple"
        placeholder="请输入省市区县"
        notFoundContent={null}
        filterOption={false}
        onChange={handleChange}
      >
        {
          map(options, o => {
            return (
              <Option value={o.value} key={o.value} className={styles.option}>
                <span>{o.name}</span>
                <span className={styles.region}>{o.region}</span>
              </Option>
            ) 
          })
        }
      </Select> 
      <SearchOutlined className={styles.iconSearch} onClick={() => onSearch?.(value)}/>
    </div>
  )
}

export default SearchSelect
