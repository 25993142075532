
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { ReactComponent as DataCountIcon } from 'src/assets/icons/data-count.svg'
import { ReactComponent as FieldCountIcon } from 'src/assets/icons/field-count.svg'
import { ReactComponent as UpdateTimeIcon } from 'src/assets/icons/update-time.svg'
import { useDialogState } from 'src/hooks/common/useDialog'
import DatabasePreviewModal from 'src/components/DatabasePreviewModal'
import DownloadModal from 'src/components/DownloadModal'
import { SearchResultItem, useDatabaseContext } from 'src/hooks/database/useDatabase'
import { SearchReturn } from 'src/types/datatable'
import styles from './searchResultList.module.scss'

interface Props {
  results: SearchReturn[]
}

export const SearchResultList: React.FC<Props> = ({ results }) => {
  const { databases, setDatabases, deselectDatabase } = useDatabaseContext()
  const [previewDatabse, setPreviewDatabse] = useState<SearchResultItem>()
  const [downloadDatabse, setDownloadDatabse] = useState<SearchResultItem>()

  const previewDialog = useDialogState()
  const downloadDialog = useDialogState()

  useEffect(() => {
    setDatabases(results.map(r => {
      return {
        id: r.id,
        count: r.count,
        fields: r.columns.length,
        name: r.title,
        updateTime: r.lastUpdateTime.substr(0, 10),
        preview: r.preview,
        columns: r.columns,
        downloadToken: r.downloadToken
      }
    }))
  }, [setDatabases, results])

  function handlePreviewClick (item: SearchResultItem) {
    setPreviewDatabse(item)
    previewDialog.open()
  }

  function handleAddClick (item: SearchResultItem) {
    setDownloadDatabse(item)
    downloadDialog.open()
  }

  function handleRemoveClick (item: SearchResultItem) {
    deselectDatabase(item)
  }

  return <>
    {databases.map((item, i) => {
      return <div className={styles.card} key={i}>
        <div className={styles.titleRow}>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.actions}>
            <Button type="link" onClick={() => handlePreviewClick(item)}>预览数据</Button>
            {
              !item.selected &&
                <Button
                  type="link" icon={<PlusOutlined />} className={styles.addButton} 
                  onClick={() => handleAddClick(item)}>加入下载清单</Button>
            }
            {
              item.selected &&
                <Button
                  type="link" danger icon={<CloseOutlined />} className={styles.removeButton} 
                  onClick={() => handleRemoveClick(item)}>移除</Button>
            }
          </div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoItem}><DataCountIcon />数据量：{item.count}</div>
          <div className={styles.infoItem}><FieldCountIcon />字段数：{item.fields}</div>
          <div className={styles.infoItem}><UpdateTimeIcon />最后更新时间：{item.updateTime}</div>
        </div>
      </div>
    })}
    <DatabasePreviewModal dialog={previewDialog} database={previewDatabse} />
    <DownloadModal dialog={downloadDialog} database={downloadDatabse} />

  </>
}

export default SearchResultList