import { message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { DataTable } from 'src/types/datatable'

export interface SearchState {
  dataTable: DataTable[];
  bounds?: {
    wkt: string;
    name: string;
  }
  region?: string[]
}

export interface Return {
  state: SearchState;
  search: (dataTable: DataTable[], region: string[]) => void;
  searchBounds: (dataTable: DataTable[], wkt: string, boundName: string) => void;
}

const searchPath = '/searchResult'

export function useRouter () : Return {
  const history = useHistory<SearchState>()
  const { state } = useLocation<SearchState>()

  if (history.location.pathname === searchPath && !state) {
    window.location.href = '/'
  }

  function search (dataTable: DataTable[], region: string[]) {
    if (dataTable.length === 0) {
      message.error('请至少选择一个数据库')
      return
    }
    
    if (region.length === 0) {
      message.error('请至少选择一个区域')
      return
    }

    if (history.location.pathname === searchPath) {
      history.replace(searchPath, { 
        dataTable,
        region
      })
    }
    else {
      history.push(searchPath, { 
        dataTable,
        region
      })
    }
  }

  function searchBounds (dataTable: DataTable[], wkt: string, name: string) {
    if (dataTable.length === 0) {
      message.error('请至少选择一个数据库')
      return
    }
    
    history.push(searchPath, { 
      dataTable,
      bounds: {
        wkt,
        name
      }
    })
  }

  return {
    state,
    search,
    searchBounds
  }
}