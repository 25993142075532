import { message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import DataTableAPI from 'src/api/datatables'
import { CoordSystemType, DataTableColumn, DataTablePreview } from 'src/types/datatable'

export interface SearchResultItem {
  id: string;
  count: number;
  fields: number;
  name: string;
  updateTime: string;
  downloadToken: string;
  columns: DataTableColumn[];
  preview: DataTablePreview[];
  selected?: boolean;
  selectedKeys?: string[]
}
interface ContextType {
  databases: SearchResultItem[];
  setDatabases: (databases: SearchResultItem[]) => void;
  selectDatabase: (databses: SearchResultItem, keys: string[]) => void;
  deselectDatabase: (database: SearchResultItem) => void
}

const DatabaseContext = React.createContext<ContextType | undefined>(undefined)

export const DatabaseProvider: React.FC = ({ children }) => {
  const [databases, setDatabases] = useState<ContextType['databases']>([])

  function selectDatabase (database: SearchResultItem, keys: string[]) {
    setDatabases(databases.map(d => {
      return d.id !== database.id ? d : {
        ...d,
        selected: true,
        selectedKeys: keys
      }      
    }))
  }

  function deselectDatabase (database: SearchResultItem) {
    setDatabases(databases.map(d => {
      return d.id !== database.id ? d : {
        ...d,
        selected: false,
        selectedKeys: undefined
      }      
    }))
  }
  
  return <DatabaseContext.Provider value={{ databases, setDatabases, selectDatabase, deselectDatabase }}>
    {children}
  </DatabaseContext.Provider>
}


export function useDatabaseContext (): ContextType {
  const context = React.useContext(DatabaseContext)
  if (context === undefined) {
    throw new Error('useDatabaseContext must be used within a DatabaseProvider')
  }
  return context
}
interface Return {
  downloadData: (databases: SearchResultItem[], coordSystemType: CoordSystemType) => void;
  isDownloading: boolean;
}

const DOWNLOAD_STATUS = 'DOWNLOAD_STATUS'

export function useDownload (): Return {
  const [ isDownloading, setIsDownloading ] = useState(false)
  const [ remainingCount, setRemainingCount ] = useState(0)
  const downloadingRef = useRef(false)

  useEffect(() => {
    if (isDownloading) {
      if (remainingCount) {
        message.loading({
          duration: 0,
          content: `下载中，文件可能较大请耐心等待，剩余数量：${remainingCount}个`,
          key: DOWNLOAD_STATUS
        })
      }
      else {
        setIsDownloading(false)
        downloadingRef.current = false
        message.success({ content: '下载完成', key: DOWNLOAD_STATUS })
      }
    }
  }, [isDownloading, remainingCount])
  
  function downloadData (databases: SearchResultItem[], coordSystemType: CoordSystemType): void {
    if (databases.length === 0) {
      return
    }
    setRemainingCount(databases.length)
    setIsDownloading(true)
    downloadingRef.current = true
    databases.forEach(async d => {
      try {
        const data = await DataTableAPI.download({
          filename: d.name,
          fields: d.selectedKeys ?? [],
          coordSystemType,
          downloadToken: d.downloadToken
        })
        if (downloadingRef.current) {
          download(data as Blob, d.name + '.csv')
          setRemainingCount(prev => prev -1)
        }
      }
      catch {
        message.destroy(DOWNLOAD_STATUS)
        setIsDownloading(false)
        downloadingRef.current = false
        setRemainingCount(0)
      }
    })
  }

  return {
    downloadData,
    isDownloading: downloadingRef.current
  }  
}


async function download (data: Blob, name: string): Promise<void> {
  const link = document.createElement('a')
  const file = new File([data], name, { type: 'application/octet-stream' })
  link.style.display = 'none'
  link.download = name
  link.href = URL.createObjectURL(file)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}