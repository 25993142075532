import { RouteProps } from 'react-router-dom'
import Home from 'src/pages/Home'
import Login from 'src/pages/Login'
import NotFound from 'src/pages/NotFound'
import SearchResult from 'src/pages/SearchResult'

export interface CustomRouteProps extends RouteProps {
  meta?: {
    noAuth?: boolean
  }
}

const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/searchResult',
    component: SearchResult
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      noAuth: true
    }
  }
] as CustomRouteProps[]

export default routes
