import React from 'react'
import LoginForm from 'src/components/LoginForm/LoginForm'
import { ReactComponent as Title } from 'src/assets/images/login-title.svg' 
import styles from './login.module.scss'

const LoginPage: React.FC = () => {
  return (
    <div className={styles.page}>
      <Title className={styles.title}/>
      <div className={styles.wrap}>
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
