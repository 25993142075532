export const OVERLAY_TYPES_MAPPING = {
  Point: 'CircleMarker',
  MultiPoint: 'Points',
  Polygon: 'Polygon',
  MultiPolygon: 'Polygons',
  LineString: 'Polyline',
  MultiLineString: 'Polylines',
  Circle: 'Circle'
}

export const OVERLAY_EDITOR_TYPES_MAPPING = {
  Point: 'marker',
  Points: 'marker',
  MultiPoint: 'marker',
  Polygon: 'polygon',
  Polygons: 'polygon',
  Polyline: 'polyline',
  Polylines: 'polyline',
  MultiPolygon: 'polygon',
  LineString: 'polyline',
  MultiLineString: 'polyline',
  Circle: 'circle'
}

export const MAP_OPTIONS = {
  token: process.env.REACT_APP_GD_TOKEN,
  style: {
    height: '100%',
    minHeight: '400px'
  },
  options: {
    mapStyle: 'normal',
    center: [113.936487, 22.516381],
    zoom: 10
  }
}

export const POLYGON_EDITOR_HINT = '提示：在地图上单击鼠标开始绘制，点击右键结束绘制。绘制完成后点击多边形可调整边界。支持添加多个图形。'
export const CIRCLE_EDITOR_HINT = '提示：滚动鼠标可缩放地图，单击鼠标标记点，可选择半径范围。'