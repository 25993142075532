import { useCallback, useState } from 'react'

export interface DialogStateReturn {
  visible: boolean;
  open: () => void;
  close: () => void;
}

export function useDialogState (initialValue = false): DialogStateReturn {
  const [visible, setVisible] = useState(initialValue)
  const open = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const close = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return {
    visible,
    open,
    close
  }
}