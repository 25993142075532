import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import storage from 'store2'
import dayjs from 'dayjs'
import AuthAPI from 'src/api/auth'
import { 
  IS_AUTH, 
  LAST_REFRESH_TOKEN_TIMESTAMP, 
  LOGIN_VERIFICATION_CODE, 
  REFRESH_TOKEN, 
  TOKEN 
} from 'src/constants/storage'
import { DEFAULT_TOKEN_EXPIRES } from 'src/constants/network'

export interface AuthStateReturn {
  isAuth: boolean;
  getCode: (phoneNumber: string) => void;
  login: (phoneNumber: string, code: string) => void;
  logout: () => void;
}

export function useAuth (): AuthStateReturn {
  const isAuth = Cookies.get(IS_AUTH) === 'true'
  const history = useHistory()

  async function login (phoneNumber: string, code: string) {
    const data = await AuthAPI.login(phoneNumber, code)
    Cookies.set(TOKEN, data.token, { expires: DEFAULT_TOKEN_EXPIRES })
    Cookies.set(REFRESH_TOKEN, data.refreshToken, { expires: DEFAULT_TOKEN_EXPIRES })
    Cookies.set(IS_AUTH, 'true')
    history.push('/')
  }

  async function getCode (phoneNumber: string) {
    return AuthAPI.getCode(phoneNumber)
  }

  function logout () {
    Cookies.remove(IS_AUTH)
    Cookies.remove(TOKEN)
    Cookies.remove(REFRESH_TOKEN)
    Cookies.remove(LAST_REFRESH_TOKEN_TIMESTAMP)
    window.location.href = '/'
  }

  return {
    isAuth,
    getCode,
    login,
    logout
  }
}

const VERIFICATION_CODE_PERIOD = 60

interface LoginReturn {
  count: number;
  isRequestCodeClick: boolean;
  countdown: () => void;
}

export function useLogin (): LoginReturn {
  const [count, setCount] = useState(0)
  const [isRequestCodeClick, setIsRequestCodeClick] = useState(false)

  const countdown = () => {
    setCount(VERIFICATION_CODE_PERIOD)
    setIsRequestCodeClick(true)
    storage.set(LOGIN_VERIFICATION_CODE, Date.now())
  }

  useEffect(() => {
    const now = Date.now()
    const loginVerificationCodeTimestamp = storage.get(LOGIN_VERIFICATION_CODE)
    const secondsToReSend = VERIFICATION_CODE_PERIOD - dayjs(now).diff(loginVerificationCodeTimestamp, 'second')
    if (secondsToReSend > 0) {
      setCount(secondsToReSend)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
    }, 1000)

    if (count === 0) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [count])

  return {
    count,
    isRequestCodeClick,
    countdown
  }
}