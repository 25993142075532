import React, { useEffect, useState } from 'react'
import { message, Modal, Table } from 'antd'
import clsx from 'clsx'
import { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import { SearchResultItem, useDatabaseContext } from 'src/hooks/database/useDatabase'
import styles from './downloadModal.module.scss'


interface Props {
  dialog: Dialog;
  database?: SearchResultItem
}

export const DownloadModal: React.FC<Props> = ({ dialog, database }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>()
  const { selectDatabase } = useDatabaseContext()

  const fieldColumns = [
    {
      title: '字段名称',
      dataIndex: 'name'
    },
    {
      title: '描述',
      dataIndex: 'description'
    },
    {
      title: '类型',
      dataIndex: 'type'
    }
  ]
  
  const fieldData = database?.columns.map(c => {
    return {
      id: c.name,
      name: c.name,
      description: c.description,
      type: c.type
    }
  })

  useEffect(() => {
    dialog.visible && setSelectedRowKeys(database?.columns?.map(c => c.name))
  }, [database, dialog.visible])

  function handleCancel () {
    dialog.close()
  }
  
  function handleOk () {
    if (selectedRowKeys?.length === 0) {
      message.error('请至少选择一个字段')
      return
    }
    database && selectedRowKeys && selectDatabase(database, selectedRowKeys)
    dialog.close()
  }

  return (
    <Modal
      centered
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      className={styles.modal}
      visible={dialog.visible} 
      width={800}
      okText="确定"
      cancelText="取消"
      destroyOnClose
    >
      <div className={styles.title}>
        选择要下载的字段
      </div>
      <Table
        rowKey="id"
        className={clsx(styles.table, styles.fieldTable)}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys.map(k => k.toString()))
        }}
        dataSource={fieldData} columns={fieldColumns} size="small" 
        scroll={{ x: 'max-content', y: '70vh' }} pagination={false}
      />
    </Modal>
  )
}

export default DownloadModal