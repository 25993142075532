import React, { useState } from 'react'
import { Empty, Layout, Spin } from 'antd'
import clsx from 'clsx'
import DatabaseSelect from 'src/components/DatabaseSelect'
import SearchSelect from 'src/components/SearchSelect'
import SearchResultList from 'src/components/SearchResultList'
import withPageHeader from 'src/hoc/withPageHeader'
import DownloadList from 'src/components/DownloadList'
import { DatabaseProvider } from 'src/hooks/database/useDatabase'
import { useRouter } from 'src/hooks/common/useRouter'
import { useDataTable, useSearchDataTable } from 'src/hooks/database/useDataTable'
import { DataTable } from 'src/types/datatable'
import styles from './searchResult.module.scss'

const { Sider, Content } = Layout

export const SearchResult: React.FC = () => {
  const { state, search, searchBounds } = useRouter()
  const [select, setSelect] = useState<DataTable[]>(state.dataTable)
  const dataTable = useDataTable()
  const { searchResults, isSearching } = useSearchDataTable(state)

  function handleSelect (d: DataTable[]) {
    setSelect(d)
  }

  function handleSearch (region: string[]) {
    if (state.bounds?.name && region[0] === state.bounds?.name) {
      searchBounds(select, state.bounds?.wkt, state.bounds?.name)
    }
    else {
      search(select, region)
    }
  }

  return (
    <DatabaseProvider>
      <Layout className={styles.page}>
        <Content className={styles.content}>
          <div className={styles.top}>
            <div className={clsx(styles.selectBox, styles.box)}>
              <DatabaseSelect dataTable={dataTable} value={state.dataTable} onSelect={handleSelect}/>
            </div> 
            <div className={clsx(styles.searchBox, styles.box)}>
              <SearchSelect
                defaultValue={state.region} onSearch={handleSearch} 
                customBoundName={state.bounds?.name} />
            </div>
          </div>
          <Spin spinning={isSearching} tip="搜索中">
            <div className={clsx(styles.bottom, styles.box)}>
              <div className={styles.bottomHeader}>
                <h3 className={styles.title}>请选择所需下载的数据</h3>
                <span className={styles.subtitle}>您可以自定义数据的字段后，加入到下载列表中</span>
              </div>
              { !isSearching && searchResults.length === 0 && 
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />}
              <SearchResultList results={searchResults} />
            </div>
          </Spin>
        </Content>
        <Sider className={styles.sider} width={380}>
          <DownloadList />
        </Sider>
      </Layout>
    </DatabaseProvider>
  )
}

export default withPageHeader(SearchResult)
