import React, { useState } from 'react'
import { Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import cx from 'clsx'
import map from 'lodash/map'
import noop from 'lodash/noop'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import styles from './searchPoi.module.scss'

const { Option } = Select

export interface SearchPOIType {
  address: string
  id: string
  location: {
    lat: number
    lng: number
  }
  name: string
  type: string
}

interface CityType {
  city: string
  province: string
}

interface PlaceSearchReturn {
  info: string
  poiList: {
    count: number
    pois: SearchPOIType[]
  }
}

interface Props {
  mapInstance: QUMapClass;
  onSelected?: (poi: SearchPOIType) => void;
}

const SearchPoi: React.FC<Props> = ({
  mapInstance,
  onSelected = noop
}) => {
  const [poiList, setPOIList] = useState<SearchPOIType[]>([])

  const [focused, setFocused] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  const searchPOI = async (keyword: string) => {
    setKeyword(keyword)
    if (!mapInstance) {
      return []
    }

    const map = mapInstance
    return new Promise((resolve) => {
      map.amap.getCity(async (city: CityType) => {
        new map.AMap.PlaceSearch({
          city: city.city ?? city.province
        })?.search(keyword, (status: string, result: PlaceSearchReturn) => {
          const { poiList } = result
          resolve(poiList?.pois)
        })
      }, map.amap.getCenter().toArray())
    })
  }

  const handleChange = async (keyword: string) => {
    setLoading(true)
    const results = await searchPOI(keyword)
    setPOIList(results as SearchPOIType[] ?? [])
    setLoading(false)
  }

  const handleSelectPOI = (val: string) => {
    onSelected?.(find(poiList, poi => poi.id === val))
  }

  const renderSearchResults = () => {
    return map(poiList, (poi: SearchPOIType) => {
      return (
        <Option
          className={styles.poiOption}
          key={poi.id}
          value={poi.id}
          label={poi.name}
        >
          <div className={styles.poiTitle}>{poi.name}</div>
          <div className={styles.poiAddress}>{poi.address}</div>
        </Option>
      )
    })
  }

  const renderNoContent = () => {
    if (!loading && keyword && !poiList.length) {
      return <>未找到相关内容。</>
    } else {
      return null
    }
  }

  return (
    <div className={styles.search}>
      <Select
        showSearch
        allowClear={true}
        placeholder="搜索地图"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={debounce(handleChange, 600)}
        onChange={handleSelectPOI}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        optionLabelProp="label"
        notFoundContent={renderNoContent()}
      >
        {renderSearchResults()}
      </Select>
      <SearchOutlined className={cx(styles.searchIcon, focused ? styles.focused : '')} />
    </div>
  )
}

export default SearchPoi
