import { useEffect, useRef, useState } from 'react'
import { Layout } from 'antd'
import DatabaseSelect from 'src/components/DatabaseSelect'
import SearchSelect from 'src/components/SearchSelect'
import Draw from 'src/components/Draw'
import type { ForwardRefHandlers } from 'src/components/Draw/Draw'
import Import from 'src/components/Import'
import withPageHeader from 'src/hoc/withPageHeader'
import { Geometry } from 'src/utils/wkt'
import { useRouter } from 'src/hooks/common/useRouter'
import { DataTable } from 'src/types/datatable'
import { useDataTable } from 'src/hooks/database/useDataTable'
import styles from './home.module.scss'

const { Sider, Content } = Layout

export const Home: React.FC = () => {
  const drawRef = useRef<ForwardRefHandlers>()
  const { search, searchBounds } = useRouter()

  const [select, setSelect] = useState<DataTable[]>([])
  const dataTable = useDataTable()

  useEffect(() => {
    setSelect(dataTable)
  }, [dataTable])

  function handleUpload (coordinates: Geometry['coordinates']) {
    drawRef.current?.showMap(coordinates)
  }
  
  function handleSelect (d: DataTable[]) {
    setSelect(d)
  }

  function handleSearch (region: string[]) {
    search(select, region)
  }

  function handleFinish (wkt: string, boundName: string) {
    searchBounds(select, wkt, boundName)
  }

  return (
    <Layout className={styles.page}>
      <Sider className={styles.sider} width={484}>
        <div className={styles.siderInner}>
          <h2 className={styles.title}>Step1 选择数据库</h2>
          <div className={styles.box}>
            <DatabaseSelect onSelect={handleSelect} dataTable={dataTable} value={select} />
          </div> 
        </div>
      </Sider>
      <Content className={styles.content}>
        <div className={styles.contentInner}>
          <h2 className={styles.title}>Step2 选择一种方式获取数据</h2>
          <div className={styles.box}>
            <SearchSelect onSearch={handleSearch} />
          </div>
          <Draw 
            ref={drawRef}
            className={styles.bar} 
            onFinish={handleFinish}
          />
          <Import 
            className={styles.bar} 
            onUpload={handleUpload}
          />
        </div>
      </Content>
    </Layout>
  )
}

export default withPageHeader(Home)
