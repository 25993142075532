
import { Button, Select } from 'antd'
import sumBy from 'lodash/sumBy'
import { useState } from 'react'
import { ReactComponent as RemoveIcon } from 'src/assets/icons/remove.svg'
import { ReactComponent as DataCountIcon } from 'src/assets/icons/data-count.svg'
import { useDownload, SearchResultItem, useDatabaseContext } from 'src/hooks/database/useDatabase'
import { CoordSystemType } from 'src/types/datatable'
import styles from './downloadList.module.scss'

const { Option } = Select

export const SearchResultList: React.FC = () => {
  const { databases, deselectDatabase } = useDatabaseContext()
  const list = databases.filter(d => d.selected)
  const selectedDatabaseFieldCount = sumBy(list, 'count') 
  const [select, setSelect] = useState<CoordSystemType>('wgs84')
  const { downloadData, isDownloading } = useDownload()

  function handleRemoveClick (item: SearchResultItem) {
    deselectDatabase(item)
  }

  function handleSelectChange (val: CoordSystemType) {
    setSelect(val)
  }

  function handleDownloadClick () {
    downloadData(list, select)
  }

  return <div className={styles.wrap}>
    <div className={styles.title}>
      下载清单
      <Button
        type="primary" className={styles.downloadButton} 
        onClick={handleDownloadClick} loading={isDownloading} disabled={isDownloading}>下载数据</Button>
    </div>
    <div className={styles.box}>
      <div className={styles.geo}>
        <div>坐标系</div>
        <Select<CoordSystemType> 
          className={styles.geoSelect} defaultValue={select} onChange={handleSelectChange}>
          <Option value="wgs84">WGS84</Option>
          <Option value="gcj02">GCJ02</Option>
          <Option value="bd09">BD09</Option>
        </Select>
      </div>
      <div className={styles.dataCount}>
        <DataCountIcon />已选数据量：{selectedDatabaseFieldCount} 条
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.listTitle}>数据列表</div>
      <div className={styles.list}>
        {list.map((item, i) => {
          return <div className={styles.card} key={i}>
            <div className={styles.left}>
              <div className={styles.cardTitle}>{item.name}</div>
              <div className={styles.cardInfo}>已选字段数：{(item.selectedKeys || []).length}/{item.fields}</div>
            </div>
            <RemoveIcon className={styles.remove} onClick={() => handleRemoveClick(item)}/>
          </div>
        })}
      </div>
    </div>
  </div>
}

export default SearchResultList