import { useState, forwardRef, useImperativeHandle, useCallback, useRef, useEffect } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import iconDraw from 'src/assets/icons/draw.png'
import Map from 'src/components/Map'
import { Geometry, GeoJSON, stringifyGeoJSON } from 'src/utils/wkt'

import styles from './draw.module.scss'

export interface ForwardRefHandlers {
  showMap: (coordinates: Geometry['coordinates']) => void 
}

interface Props {
  className?: string;
  onFinish?: (wkt: string, name: string) => void;
}

const MAX_AREA = 50000

export const Draw = forwardRef((props: Props, ref) => {
  const { className, onFinish } = props
  const [mapShow, setMapShow] = useState(false)
  const [defaultBoundName, setDefaultBoundName] = useState('绘制的边界')
  const [confirmModalShow, setConfirmModalShow] = useState(false)
  const [preData, setPreData] = useState<Geometry['coordinates']>()
  const [area, setArea] = useState(0)
  const [geoJson, setGeoJson] = useState<GeoJSON>()
  const [form] = Form.useForm()
  const inputRef = useRef<Input>(null)

  useImperativeHandle(ref, () => {
    return {
      showMap (coordinates: Geometry['coordinates']) {
        setMapShow(true)
        setPreData(coordinates)
        setDefaultBoundName('导入的边界')
      }
    }
  })

  useEffect(() => {
    if (confirmModalShow) {
      inputRef.current?.focus()
    }
  }, [confirmModalShow])

  function handleClick () {
    setPreData(undefined)
    setMapShow(true)
  }

  const handleSubmit = useCallback((geoJson: GeoJSON, totalArea: number) => {
    setConfirmModalShow(true)
    setTimeout(() => {
      inputRef.current?.select()
    }, 0)
    setArea(totalArea)
    setGeoJson(geoJson)
  }, [])

  const handleClose = useCallback(() => {
    setMapShow(false)
  }, [])

  async function handleFinish () {
    try {
      await form.validateFields()
      geoJson && onFinish?.(stringifyGeoJSON(geoJson), form.getFieldValue('name'))
    } catch (error) {
      message.info(error)
    }
  }

  return (
    <>
      <div className={className} onClick={handleClick}>
        <div>
          <img src={iconDraw} alt="icon" className={styles.icon} />
          <span>绘制边界获取数据</span>
        </div>
        <ArrowRightOutlined />
      </div>
      <Map 
        onSubmit={handleSubmit}
        visible={mapShow} 
        onClose={handleClose}
        preData={preData}
      />
      <Modal
        centered
        className={styles.modal}
        width="380px"
        visible={confirmModalShow}
        title={null}
        footer={null}
        onCancel={() => setConfirmModalShow(false)}
        destroyOnClose
      >
        <div className={styles.formWrap}>
          <Form
            initialValues={
              { name: defaultBoundName }
            }
            colon={false}
            labelAlign="left"
            labelCol={{ span: 6 }}
            onFinish={handleFinish}
            requiredMark={false}
            form={form}
          >
            <Form.Item
              label="区域名称"
              name="name"
              rules={[
                {
                  required: true,
                  message: '请输入名称'
                }
              ]}
            >
              <Input autoComplete="off" ref={inputRef} />
            </Form.Item>
            <Form.Item>
              <div>当前区域面积 {area} 平方公里</div>
              {
                area >= MAX_AREA && <div className={styles.warning}>区域面积过大，请重新绘制/导入（ ≤{MAX_AREA} 平方公里）</div>
              }
            </Form.Item>
            <Form.Item
              className={styles.modalFooter}
            >
              <Button onClick={() => setConfirmModalShow(false)}>取消</Button>
              <Button type="primary" htmlType="submit" disabled={area >= MAX_AREA}>确定</Button>
            </Form.Item>
          </Form>  
        </div>
      </Modal>
    </>
  )
})

export default Draw