import { ComponentType } from 'react'
import PageHeader from 'src/components/PageHeader'

const withPageHeader = (Comp: ComponentType): ComponentType => {
  const ComponentWithLayout: ComponentType = (props) => {
    return (
      <>
        <PageHeader />
        <Comp {...props} />
      </>
    )
  }

  return ComponentWithLayout
}

export default withPageHeader
