import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { validatePhoneNumber } from 'src/utils/validators'
import { useAuth, useLogin } from 'src/hooks/common/useAuth'
import styles from './loginForm.module.scss'

const LoginForm: React.FC = () => {
  const [form] = Form.useForm()
  const { getCode, login } = useAuth()
  const { count, isRequestCodeClick, countdown } = useLogin()

  async function handleRequestCodeClick () {
    const phoneNumber = form.getFieldValue('phoneNumber')

    if (validatePhoneNumber(phoneNumber)) {
      await getCode(phoneNumber)
      countdown()

      message.success('验证码已发送至您的手机，请注意查收')
    } else {
      message.error('请确认您的手机号码是否输入正确')
    }
  }

  function handleFormSubmit () {
    const phoneNumber = form.getFieldValue('phoneNumber')
    const code = form.getFieldValue('code')
    login(phoneNumber, code)
  }

  return (
    <Form
      className={styles.form}
      name="login"
      size="large"
      form={form}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        name="phoneNumber"
        validateTrigger="onBlur"
        rules={[
          {
            validator (_, val) {
              return validatePhoneNumber(val) ? Promise.resolve() : Promise.reject('请确认您的手机号码是否输入正确')
            }
          }
        ]}
      >
        <Input placeholder="手机号码" />
      </Form.Item>
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: '请输入验证码'
          }
        ]}
      >
        <Input
          placeholder="验证码" autoComplete="off" suffix={
            <Button
              size="small"
              className={styles.captchaButton}
              block
              type="primary"
              onClick={handleRequestCodeClick}
              disabled={count > 0}
            >
              {count > 0 ? count :
                (isRequestCodeClick ? '重发' : '发送')
              }
            </Button>
          } />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          className={styles.button}
          type="primary"
          htmlType="submit"
          block
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
