import React from 'react'
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import routes, { CustomRouteProps } from 'src/routes'
import { useAuth } from './hooks/common/useAuth'

const AuthRoute: React.FC<CustomRouteProps> = (props) => {
  const { isAuth } = useAuth()
  return (
    isAuth ? <Route {...props} /> : <Redirect to="/login" />
  )
}

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          {
            routes.map((r, i) => {
              if (r?.meta?.noAuth) {
                return (
                  <Route key={i} {...r} />
                )
              } else {
                return <AuthRoute key={i} {...r} />
              }
            })
          }
        </Switch>
      </Router>
    </div>
  )
}

export default App
