import React, { useRef, useState, useEffect } from 'react'
import reduce from 'lodash/reduce'
import { Modal, Button, Radio, RadioChangeEvent, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { QUMap, Controls, Zoom, Editor, TileLayer } from 'qumap/packages/react'
import type { QUMap as QUMapClass } from 'qumap/packages/core'
import { Props as EditorProps } from 'qumap/packages/react/types/editor/Editor'
import PolygonEditor from 'qumap/packages/core/types/polygonEditor'
import CircleEditor from 'qumap/packages/core/types/circleEditor'
import { ReactComponent as CircleIcon } from 'src/assets/icons/circle.svg'
import { ReactComponent as PolygonIcon } from 'src/assets/icons/polygon.svg'
import { MAP_OPTIONS, POLYGON_EDITOR_HINT, CIRCLE_EDITOR_HINT } from 'src/constants/map'
import { Geometry, GeoJSON } from 'src/utils/wkt'
import SearchPoi from 'src/components/SearchPoi'
import { SearchPOIType } from 'src/components/SearchPoi/SearchPoi'
import styles from './map.module.scss'

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  coordinates?: string | unknown;
  onClear: () => void;
  isImport: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onCancel,
  onClear,
  onSubmit,
  isImport
}) => {
  return (
    <div className={styles.footer}>
      {!isImport && <Button danger type="link" onClick={onClear}>清空绘制</Button>}
      <Button onClick={onCancel}>取消</Button>
      <Button type="primary" onClick={onSubmit}>确定</Button>
    </div>
  )
}

interface HeaderProps {
  onClose: () => void;
  editorType: string;
  onEditorTypeChange: (t: EditorProps['type']) => void;
  isImport: boolean;
  quMap: QUMapClass | null;
}

const Header: React.FC<HeaderProps> = ({
  onClose,
  editorType,
  onEditorTypeChange,
  isImport,
  quMap
}) => {
  function handleChange (e: RadioChangeEvent) {
    onEditorTypeChange(e.target.value)
  }

  function handlePoiSelect (poi: SearchPOIType) {
    if (poi && quMap) {
      const center = [poi.location.lng, poi.location.lat]
      quMap.amap.setCenter(center as [number, number])
    }
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <h3 className={styles.title}>
          {isImport ? '导入的边界' : '绘制'}
        </h3>
        {
          !isImport && <Radio.Group className={styles.radioGroup} value={editorType} onChange={handleChange}>
            <div className={styles.editorList}>
              <Radio value="polygon">
                <div className={styles.eidtorItem}>
                  <PolygonIcon className={styles.editorIcon} />
                面
                </div>
              </Radio>
              <Radio value="circle">
                <div className={styles.eidtorItem}>
                  <CircleIcon className={styles.editorIcon} />
                圆形区域
                </div>
              </Radio>
            </div>
          </Radio.Group>
        }
      </div>
      <div className={styles.headerRight}>
        <div className={styles.searchBox}>
          {
            quMap && <SearchPoi 
              mapInstance={quMap}
              onSelected={handlePoiSelect}
            />
          }
        </div>
        <CloseOutlined onClick={onClose} />
      </div>
    </div>
  )
}

interface TipsProps {
  editorType: EditorProps['type']
}

const Tips: React.FC<TipsProps> = ({
  editorType
}) => {
  const [showTip, setShowTip] = useState(true)

  useEffect(() => {
    setShowTip(true)
  }, [editorType])

  return (
    showTip ? <div className={styles.tips}>
      提示：
      {
        editorType === 'polygon' && POLYGON_EDITOR_HINT
      }
      {
        editorType === 'circle' && CIRCLE_EDITOR_HINT
      }
      <div className={styles.tipConfirm} onClick={() => setShowTip(false)}>知道了</div>
    </div> : null
  )
}


interface Props {
  visible: boolean;
  onClose: () => void;
  preData?: Geometry['coordinates'];
  onSubmit: (geoJson: GeoJSON, totalArea: number) => void;
}

export const Map: React.FC<Props> = ({
  visible,
  preData,
  onClose,
  onSubmit
}) => {
  const editor = useRef<PolygonEditor | CircleEditor>(null)
  const quMap = useRef<QUMapClass>(null)
  const [mapReady, setMapReady] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const [editorType, setEditorType] = useState<EditorProps['type']>('polygon')
  
  function handleSubmit () {
    const geoJson: GeoJSON = editor.current?.getOverlaysGeoJSON()

    if (!geoJson) {
      message.info('请在地图上绘制选取或导入数据')
    } else {
      let totalArea = 0 
      if (editorType === 'polygon') {
        totalArea = reduce(geoJson.features, (acc, current) => {
          const area = quMap.current?.AMap.GeometryUtil.ringArea(current.geometry.coordinates[0])

          return acc + area  
        }, 0)

      }

      if (editorType === 'circle') {
        const circle = editor.current?.editor.getTarget()

        if (circle) {
          totalArea = circle.getArea()   
        }
      }

      onSubmit(geoJson, Math.round(totalArea / Math.pow(1000, 2)))
    }
  }

  function handleClear () {
    editor.current?.reset()
  }

  function handleModalClose () {
    onClose()
    setIsImport(false)
  }

  function handleEdtiorFinish () {
    editor.current?.next()
  }

  function handleEditorTypeChange (type: EditorProps['type']) {
    editor.current?.reset()
    setEditorType(type)
  }

  useEffect(() => {
    if (preData) {
      setIsImport(true)
    } else {
      setIsImport(false)
    }

    if (preData && mapReady) {
      setEditorType('polygon') 
      editor.current?.reset()
      setTimeout(() => {
        (editor.current as PolygonEditor)?.load(preData)
        quMap.current?.amap.setFitView(quMap.current?.amap.getAllOverlays(), true)
      })
    }
  }, [preData, mapReady])

  return (
    <Modal 
      centered
      className={styles.modal}
      visible={visible}
      closable={false}
      width="90%"
      title={
        <Header 
          isImport={isImport}
          onClose={onClose}
          editorType={editorType}
          onEditorTypeChange={handleEditorTypeChange}
          quMap={quMap.current}
        />
      }
      footer={
        <Footer
          isImport={isImport}
          onCancel={handleModalClose}
          onSubmit={handleSubmit}
          onClear={handleClear}
        />
      }
    >
      <QUMap {...MAP_OPTIONS} quMapRef={quMap} plugins={['AMap.PlaceSearch']} onLoad={() => setMapReady(true)}>
        <Controls>
          <Zoom />
          <TileLayer
            defaultTileLayer={'AmapStandard'}
          />
        </Controls>
        <Editor
          ref={editor}
          type={editorType}
          onFinish={handleEdtiorFinish}
        />
      </QUMap>
      <Tips editorType={editorType} />
    </Modal>
  )
}

export default React.memo(Map)