import * as china from 'china-region'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import map from 'lodash/map'

declare global {
  interface Window { ALL_REGION: ReturnType<typeof china.getAllRegions> }
}

if (!window.ALL_REGION) {
  window.ALL_REGION = china.getAllRegions()
} 

const region = window.ALL_REGION

interface QueryResult {
  code: string;
  name: string;
  text: string;
}

function getFullText (info: NonNullable<ReturnType<typeof china.info>>) {
  let result = info.province
  if (info.prefecture !== info.province) {
    result += info.prefecture || ''
  }

  if (info.name !== info.province && info.name !== info.prefecture) {
    result += info.name || ''
  }
  return result
}

export function queryRegion (s: string): QueryResult[] {
  const matchRegions = filter(region, d => d.name.includes(s))
  return queryCodes(map(matchRegions, d => d.code))
}

export function queryCodes (codes: string[]): QueryResult[] {
  const infos = compact(map(codes, code => china.info(code)))
  return map(infos, info => {
    return {
      code: info.code,
      name: info.name,
      text: getFullText(info)
    }
  })
}
