import AppConfig from 'src/config'
import { DataTable, DownloadParams, SearchParams, SearchReturn } from 'src/types/datatable'
import { createInstance } from 'src/utils/fetch'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/datatable/`
})


const DataTableAPI = {
  async get (): Promise<DataTable[]> {
    const { data } = await fetch.get('datatables/', {
      baseURL: `${AppConfig.baseApiURL}/`
    })

    return data.detail
  },
  async search (params: SearchParams): Promise<Omit<SearchReturn, 'id'>> {
    const res = await fetch.post('searches/', params)
    return res.data.detail
  },
  async download (params: DownloadParams): Promise<unknown> {
    const res = await fetch.post('download/', params,
      {
        timeout: 0,
        responseType: 'blob'
      })
    return res.data
  }
}

export default DataTableAPI
